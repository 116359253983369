import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            about_factory_gsap: null,
            about_factory_gallery_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            this.RegisterGsap("About")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:769px)"() {
                    vm.about_factory_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutFactory",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box", {
                        scaleX: 0,
                    }, {
                        scaleX: 1,
                        duration: .4,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box .cover", {
                        scaleX: 1,
                    }, {
                        scaleX: 0,
                        delay: .3,
                        duration: .4,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box img", {
                        scale: 1.2,
                    }, {
                        scale: 1,
                        delay: .5,
                        duration: 2,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.set("#AboutFactory header .content", {
                        color: "#000"
                    })
                    vm.about_factory_gsap.fromTo("#AboutFactory header .content", {
                        scaleX: 0,
                    }, {
                        scale: 1,
                        delay: .3,
                        duration: .4,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.fromTo("#AboutFactory header .content", {
                        color: "#000",
                    }, {
                        color: "#fff",
                        delay: .6,
                        duration: 1,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.set("#AboutFactory header .content", {
                        color: "#fff"
                    })

                    vm.about_factory_gallery_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutFactory .gallery",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                        }
                    })
                    vm.about_factory_gallery_gsap.fromTo("#AboutFactory .gallery", {
                        webkitFilter: "blur(40px)",
                        opacity: 0
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        ease: "none"
                    })

                },
                "(max-width:768px)"() {
                    vm.about_factory_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutFactory",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                        }
                    })
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box", {
                        scaleX: 0,
                    }, {
                        scaleX: 1,
                        duration: .4,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box .cover", {
                        scaleX: 1,
                    }, {
                        scaleX: 0,
                        delay: .3,
                        duration: .4,
                        ease: "none",
                    }, 0)
                    vm.about_factory_gsap.fromTo("#AboutFactory header .img_box img", {
                        scale: 1.2,
                    }, {
                        scale: 1,
                        delay: .5,
                        duration: 2,
                        ease: "none",
                    }, 0)

                    vm.about_factory_gsap.fromTo("#AboutFactory header .content", {
                        opacity: 0,
                        y: "50px"
                    }, {
                        opacity: 1,
                        y: "0px",
                        delay: .3,
                        duration: .4,
                        ease: "none",
                    }, 0)

                    vm.about_factory_gallery_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutFactory .gallery",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                        }
                    })
                    vm.about_factory_gallery_gsap.fromTo("#AboutFactory .gallery", {
                        webkitFilter: "blur(40px)",
                        opacity: 0
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        ease: "none"
                    })
                }
            })
            this.InitGsapMarker()
        }
    },
    beforeDestroy() {
        this.about_factory_gsap != null ? this.about_factory_gsap.kill() : ""
        this.about_factory_gallery_gsap != null ? this.about_factory_gallery_gsap.kill() : ""
    }
}