<template>
  <section id="AboutStory">
    <div class="row">
      <header>
        <h2><img src="/img/nav_logo_1.svg" alt="MJP" /></h2>
      </header>

      <div class="content_box">
        <div class="content" v-html="content"></div>

        <div class="bg_text_box">
          <span>ABOUT</span>
        </div>
      </div>
      <img class="story_img" :src="image" />
    </div>
  </section>
</template>

<script>
import AboutStoryGSAP from "@/common/GSAP/About/about_story.js"
export default {
  name: "AboutStory",
  mixins: [AboutStoryGSAP],
  props: {
    section_data: {
      require: true
    }
  },
  data() {
    return {
      content: "",
      image: "",
      title: ""
    }
  },
  watch: {
    section_data() {
      if (Object.keys(this.section_data).length > 0) {
        this.content = this.section_data[0].content
        this.image = this.section_data[0].image
        this.InitGSAP()
      }
    }
  },
  mounted() { }
}
</script>