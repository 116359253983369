import {
    get,
    post
} from '@/common/request'


export function getPage() {
    //輪播
    var carousel_data = get('web_edit/about_carousel/get_about_carousel.php')
    //關於
    var about = post('web_edit/get_common_column.php', {
        column_array: ["about"]
    })
    //品質介紹
    var quality = post('web_edit/get_common_column.php', {
        column_array: ["quality_content_1", "quality_content_2", "quality_content_3"]
    })
    //工廠相簿
    var gallery = get('web_edit/about_gallery/get_about_carousel.php')
    //公司資訊
    var company_info = post('web_edit/get_common_column.php', {
        column_array: ["company_name", "company_create_date", "company_address", "company_phone", "company_fax", "company_tax_id"]
    })

    let promise_list = [carousel_data, about, quality, gallery, company_info]

    return Promise.all(GetPromise(promise_list))
        .then(
            res => {
                if (promise_list.length == res.length) {
                    return (res)
                }
            }, err => console.log(err)
        )
}

function GetPromise(promiseList) {
    return promiseList.map(promise =>
        promise.then((res) => res)
    )
}

export function getCarousel() {
    return get('web_edit/about_carousel/get_about_carousel.php')
}

export function getAbout() {
    return post('web_edit/get_common_column.php', {
        column_array: ["about"]
    })
}

export function getQuality() {
    return post('web_edit/get_common_column.php', {
        column_array: ["quality_content_1", "quality_content_2", "quality_content_3"]
    })
}

export function getGallery() {
    return get('web_edit/about_gallery/get_about_carousel.php')
}

export function getInfo() {
    return post('web_edit/get_common_column.php', {
        column_array: ["company_name", "company_create_date", "company_address", "company_phone", "company_fax", "company_tax_id"]
    })
}