<template>
  <main id="About">
    <Cover :images="page_data.carousel" />
    <Story :section_data="page_data.about" />
    <Quality :quality_content="page_data.quality" />
    <Factory :gallery="page_data.gallery" />
    <Info :company_info="page_data.company_info" />
    <MainFooter />
  </main>
</template>

<script>
import GSAP from '@/common/SmoothScrollResister.js';
import Cover from '@/components/About/Cover';
import Story from '@/components/About/Story';
import Quality from '@/components/About/Quality';
import Factory from '@/components/About/Factory';
import Info from '@/components/About/Info';
import MainFooter from '@/components/MainFooter';
import { getPage } from '@/api/about.js';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'About',
  mixins: [GSAP],
  components: {
    Cover,
    Story,
    Quality,
    Factory,
    Info,
    MainFooter,
  },
  data() {
    return {
      page_data: {
        carousel: [],
        about: {},
        quality: [],
        gallery: [],
        company_info: [],
      },
    };
  },
  methods: {
    async GetData() {
      this.$store.commit('SetPageLoading', 1);
      await getPage().then((res) => {
        res.forEach((data, index) => {
          let key = Object.keys(this.page_data)[index];
          this.page_data[key] = data.data;
        });
      });
      this.$store.commit('SetPageLoading', -1);
      this.$nextTick(() => {
        window.prerenderReady = true;
      });
    },
  },
  created() {
    this.GetData();
    this.meta_data = GetMetaData('關於我們', '', '');
  },
  mounted() {
    this.RegisterGsap('About');
    this.$emit('init-cursor');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
