import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            about_info_gsap: null,
            about_info_box_gsap: null
        }
    },
    methods: {
        InitGSAP() {
            this.RegisterGsap("About")
            let vm = this
            ScrollTrigger.matchMedia({
                "(min-width:769px)"() {
                    vm.about_info_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutInfo",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_info_gsap.fromTo("#AboutInfo header .title b", {
                        y: "100%",
                    }, {
                        y: 0,
                        duration: .4,
                        ease: "power2.out",
                    }, 0)
                    vm.about_info_gsap.fromTo("#AboutInfo header .sub_title b", {
                        y: "100%",
                    }, {
                        y: "0px",
                        delay: .5,
                        duration: .4
                    }, 0)

                    vm.about_info_box_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutInfo .info_box",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                        }
                    })
                    vm.about_info_box_gsap.fromTo("#AboutInfo .info_box", {
                        webkitFilter: "blur(40px)",
                        opacity: 0
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        ease: "none",
                        duration: 1
                    })
                },
                "(max-width:768px)"() {
                    vm.about_info_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutInfo",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_info_gsap.fromTo("#AboutInfo header .title b", {
                        y: "100%",
                    }, {
                        y: 0,
                        duration: .4,
                        ease: "power2.out",
                    }, 0)
                    vm.about_info_gsap.fromTo("#AboutInfo header .sub_title b", {
                        y: "100%",
                    }, {
                        y: "0px",
                        delay: .5,
                        duration: .4
                    }, 0)

                    vm.about_info_box_gsap = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#AboutInfo .info_box",
                            start: "top center",
                            end: "center center",
                            toggleActions: "restart play play reverse",
                            // markers: true
                        }
                    })
                    vm.about_info_box_gsap.fromTo("#AboutInfo .info_box", {
                        webkitFilter: "blur(40px)",
                        opacity: 0
                    }, {
                        webkitFilter: "blur(0px)",
                        opacity: 1,
                        ease: "none",
                        duration: 1
                    })
                }
            })
            this.InitGsapMarker()
        }
    },
    beforeDestroy() {
        this.about_info_gsap != null ? this.about_info_gsap.kill() : ""
        this.about_info_box_gsap != null ? this.about_info_box_gsap.kill() : ""
    }
}