import gsap from "gsap"
import SmoothScrollResister from "@/common/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"



export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            about_cover_gsap: null,
            active_img: 0
        }
    },
    methods: {
        PlayAnimate() {
            let vm = this
            this.about_cover_gsap = gsap.timeline()
            this.about_cover_gsap.fromTo(
                "#AboutCover .bg_img_box .img_box.active", {
                    opacity: 1,
                    scale: 1.2,
                }, {
                    scale: 1,
                    opacity: 1,
                    ease: "none",
                    duration: 5
                }
            )
            this.about_cover_gsap.set("#AboutCover .bg_img_box .img_box", {
                opacity: 1
            })
            this.about_cover_gsap.fromTo("#AboutCover .bg_img_box .img_box.active", {
                opacity: 1,
            }, {
                opacity: 0,
                ease: "none",
                duration: 2,
                onComplete: vm.GetNextElement
            })
            this.about_cover_gsap.set("#AboutCover .bg_img_box .img_box", {
                scale: 1.2
            })
        },
        GetNextElement() {
            let imgs = document.querySelectorAll("#AboutCover .bg_img_box .img_box")
            this.active_img == imgs.length - 1 ? this.active_img = 0 : this.active_img += 1

            document.querySelectorAll("#AboutCover .bg_img_box .img_box.active")[0].classList.remove("active")
            document.querySelectorAll("#AboutCover .bg_img_box .img_box")[this.active_img].classList.add("active")
            this.PlayAnimate()
        }
    },
    mounted() {
        // document.querySelectorAll("#AboutCover .bg_img_box .img_box")[0].classList.add("active")
        // this.PlayAnimate()
        // this.RegisterGsap("About")
        // this.InitGsapMarker()
    },
    beforeDestroy() {
        this.about_cover_gsap != null ? this.about_cover_gsap.kill() : ""
    }
}