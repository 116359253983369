<template>
  <section id="AboutFactory">
    <div class="photobanner">
      <div class="photo_wraper">
        <img src="/img/about_image_scroll/1.jpg" />
        <img src="/img/about_image_scroll/2.jpg" />
        <img src="/img/about_image_scroll/3.jpg" />
        <img src="/img/about_image_scroll/4.jpg" />
        <img src="/img/about_image_scroll/5.jpg" />
      </div>
      <div class="photo_wraper">
        <img src="/img/about_image_scroll/1.jpg" />
        <img src="/img/about_image_scroll/2.jpg" />
        <img src="/img/about_image_scroll/3.jpg" />
        <img src="/img/about_image_scroll/4.jpg" />
        <img src="/img/about_image_scroll/5.jpg" />
      </div>
    </div>
    <div class="row">
      <header>
        <div class="content">
          所有品項的生產、研發都是透過公司自有的獨立工廠，品質控管嚴格把關更有保障，
          我們堅信穩定的品質才是品牌永續經營的關鍵。
        </div>
        <div class="img_box">
          <div class="cover"></div>
          <div class="title_group">
            <h3>工廠介紹</h3>
            <p>FACTORY</p>
          </div>
          <img src="/img/factory.jpg" />
        </div>
      </header>
      <div class="gallery">
        <div
          @click="show_more = true"
          :class="{ hidden: show_more }"
          class="show_more_cover"
        >
          <button class="more_btn">
            <div>
              <b>SHOW MOER</b>
            </div>
          </button>
        </div>
        <div
          :class="{ active: show_more }"
          class="img_box zoom_mark"
          v-for="(item, item_index) in gallery"
          :key="item.key"
          @click="OpenImage(item_index)"
        >
          <img :src="item.url" />
        </div>
        <div
          @click="show_more = false"
          :class="{ hidden: !show_more }"
          class="show_less_cover"
        >
          <button class="more_btn">
            <div>
              <b>SHOW LESS</b>
            </div>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import about_factory_gsap from "@/common/GSAP/About/about_factory.js"
export default {
  name: "AboutFactory",
  mixins: [about_factory_gsap],
  props: {
    gallery: {
      require: true
    }
  },
  data() {
    return {
      show_more: false
    }
  },
  watch: {
    gallery() {
      if (this.gallery.length > 0) {
        this.$nextTick(() => {
          this.InitGSAP()
        })
      }
    }
  },
  methods: {
    OpenImage(index) {
      let images = []
      this.gallery.forEach(item => images.push(item.url))
      this.$store.commit("SetImageModal", {
        status: true,
        images: images,
        active_index: index
      })
    },
  },
}
</script>